<template>
  <div>
    <div class="accordion-header mb-3">
      <div class="px-1 py-1 d-flex items-start justify-content-between rounded-sm"
           style="border-bottom: 2px solid rgba(150, 150, 150, 0.6) !important;">
        <div class="d-flex items-baseline justify-content-start cursor-pointer" @click="toggleBody">
          <span v-if="Number(productDetail.sample_type_id) === 1"
                v-html="getIconByKey('icons.sample.k-product', {})"
          ></span>
          <span v-if="Number(productDetail.sample_type_id) === 2"
                v-html="getIconByKey('icons.sample.fabric', {})"
          ></span>
          <span v-if="Number(productDetail.sample_type_id) === 3"
                v-html="getIconByKey('icons.sample.accessory', {})"
          ></span>

          <h6 class="mx-4 accordion-title">{{ itemIndex }}. {{ productDetail.sample_type.translations[0].name }} : <span class="text-primary"> {{ productDetail.name }} </span></h6>
          <span><i class="text-primary fas" :class="{'fa-caret-down': !showBody, 'fa-caret-right': showBody}"></i></span>
        </div>
        <div class="d-flex items-end justify-content-start">
          <label class="checkbox checkbox-outline checkbox-outline-2x checkbox-primary">
            <input type="checkbox" :value="productDetail.id" :checked="isChecked" name="" v-model="checkBoxStatusesProxy">
            <span style="border-radius: 8px;"></span>
          </label>
        </div>
      </div>
      <transition name="fade">
        <div class="accordion-body bg-gray-50 mb-8 px-8 py-5 border-2 border-black shadow-sm" v-show="showBody">
          <template v-if="productDetail.contents !== null && productDetail.contents.length !== 0">
            <template v-if="productContentByType(1).length !== 0">
              <h1>{{ $t('general.text') }}</h1>
              <hr>
              <div class="row py-5">
                <b-col md="4" offset-md="4" v-for="(content, index) in productContentByType(1)" :key="index">
                  <meeting-text-holder-card :content="content"></meeting-text-holder-card>
                </b-col>
              </div>
            </template>

            <template v-if="productContentByType(2).length !== 0">
              <h1>{{ $t('general.videos') }}</h1>
              <hr>
              <div class="row py-5">
                <b-col md="4" offset-md="4" v-for="(content, index) in productContentByType(2)" :key="index">
                  <meeting-video-holder-card :content="content"></meeting-video-holder-card>
                </b-col>
              </div>
            </template>

            <template v-if="productContentByType(3).length !== 0">
              <h1>{{ $t('general.images') }}</h1>
              <hr>
              <div class="row py-5">
                <b-col md="4" offset-md="4" v-for="(content, index) in productContentByType(3)" :key="index">
                  <meeting-image-holder-card :content="content"></meeting-image-holder-card>
                </b-col>
              </div>
            </template>

            <template v-if="productContentByType(4).length !== 0">
              <h1>{{ $t('general.audios') }}</h1>
              <hr>
              <div class="row py-5">
                <b-col md="4" offset-md="4" v-for="(content, index) in productContentByType(4)" :key="index">
                  <meeting-audio-player-holder-card :contents="content"></meeting-audio-player-holder-card>
                </b-col>
              </div>
            </template>
          </template>
          <template v-if="productDetail.contents === null || productDetail.contents.length == 0">
            <h5>{{ $t('general.there_is_content_available')}}</h5>
          </template>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import AudioPlayer from "@/assets/components/mediaPlayer/AudioPlayer";
import MeetingImageHolderCard from "@/view/pages/samples/layout/meeting-detail/MeetingImageHolderCard";
import MeetingVideoHolderCard from "@/view/pages/samples/layout/meeting-detail/MeetingVideoHolderCard";
import MeetingAudioPlayerHolderCard from "@/view/pages/samples/layout/meeting-detail/MeetingAudioPlayerHolderCard";
import MeetingTextHolderCard from "@/view/pages/samples/layout/meeting-detail/MeetingTextHolderCard";

export default {
  name: "MeetingDetailAccordion",
  props: {
    productDetail: {
      required: true,
    },
    itemIndex: {
      required: true,
    },
    checkBoxStatuses: {
      required: true,
      type: Array,
    }
  },
  components: {
    AudioPlayer,
    MeetingImageHolderCard,
    MeetingVideoHolderCard,
    MeetingAudioPlayerHolderCard,
    MeetingTextHolderCard,
  },
  computed: {
    checkBoxStatusesProxy: {
      set(value) {
        this.$emit('update:checkBoxStatuses', value);
      },
      get() {
        return this.checkBoxStatuses;
      }
    },
    isChecked() {
      for (let i = 0; i < this.checkBoxStatuses.length; i++) {
        if (this.checkBoxStatuses[i] == this.checkBoxValue) return true;
      }
      return false;
    },

  },
  data() {
    return {
      id: null,
      showBody: false,
    };
  },
  methods: {
    toggleBody() {
      this.showBody = !this.showBody;
    },
    productContentByType(type) {
      let results = [];
      for (let i = 0; i < this.productDetail.contents.length; i++) {
        let currentContent = this.productDetail.contents[i];
        if (Number(currentContent.content_type_id) === Number(type)) {
          results.push(currentContent);
        }
      }
      return results;
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/components/_variables.demo.scss';


.product-name {
  color: $primary !important
}

.fade-enter-active {
  transition: opacity .01s;
}

.fade-leave-active {
  transition: opacity 0.01s;
}

.fade-enter /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.fade-leave-to {
  transition: opacity 0.01s;
}

.checkbox.checkbox-primary > input:checked ~ span {
  background-color: #fff !important;
}

.checkbox.checkbox-outline.checkbox-primary > input:checked ~ span::after {
  border-color: $primary;
}

.checkbox.checkbox-outline.checkbox-primary > input:checked ~ span {
  background-color: #fff;
  border-color: #d6d6e0;
}
</style>