<template>
  <div class="row">
    <div class="col-md-12">
      <dashboard-box :back="true" back-component-name="sample.create.meeting-list">
        <template v-slot:title>
          <span>{{ meeting.name }}</span>
        </template>
        <template v-slot:preview>
          <div class="mb-4" >
            <p class="text-center text-primary">
              <span class="d-inline-block mr-4">
                <span class="d-flex items-center font-weight-bolder">
                  <span v-html="getIconByKey('icons.meeting.calender', {
                    class: 'w-25px h-25px object-cover mr-2 d-inline-block'
                  })"></span> {{ meeting.date_hour | momentDateDayFormat }}
                </span>
              </span>
              <span class="d-inline-block">
                <span class="d-flex items-center font-weight-bolder">
                  <span v-html="getIconByKey('icons.meeting.clock', {
                        class: 'w-25px h-25px object-cover mr-2 d-inline-block'
                  })"></span>
                {{ meeting.date_hour | momentHourAndMinuteFormat }}
                </span>
              </span>
            </p>
            <h3 class="text-center mb-2 pb-2" v-if="meeting.company" style="border-bottom: 2px solid rgba(150, 150, 150, 0.6);">
              <img class="company-thumbnail-photo rounded-circle w-35px h-35px company-image-border object-contain" :src="meeting.company.avatar_file"/>
              {{ meeting.company.name }}
            </h3>
          </div>
          <h3 v-b-toggle.meeting_attendees class="cursor-pointer pb-2 mb-5 d-flex justify-content-center items-center"
              variant="info"
              style="border-bottom: 2px solid rgba(150, 150, 150, 0.6); align-items: center;">
            <span
                v-html="getIconByKey('icons.meeting.meeting-participant-1', {class: 'w-35px h-35px object-cover d-inline-block mr-3'})"></span>
            <span>{{ $t('editSample.meeting_attendees') }}</span>
            <i class="fas fa-caret-down ml-3"></i>
          </h3>
          <b-collapse class="row pb-5 px-10" id="meeting_attendees">
            <div
                class="col-12 px-2 py-2 d-flex align-items-top justify-content-between cursor-pointer"
                v-for="user in sampleUsers"
                style="border-bottom: 1px solid rgba(156, 163, 175, 0.2)">
              <div class="d-flex items-baseline align-items-center justify-content-start cursor-pointer">
            <span>
              <img
                  :src="user.avatar_file"
                  class="rounded-full w-25px h-25px bg-gray-200 user-image"
                  alt="">
            </span>
                <h6 class="mx-4">{{ user.name }}</h6>
              </div>
            </div>

          </b-collapse>

          <h3 class="my-5 text-center d-flex justify-content-center items-center">
            <span v-html="getIconByKey('icons.sample.collection-product-information', {
              class: 'w-35px h-35px object-cover d-inline-block mr-3'
            })"></span>
            {{ $t('createOriginalSample.collection_product_information') }}</h3>

          <div class="px-4">
            <meeting-detail-accordion v-for="(product, index) in meeting.meeting_products"
                                      :key="index"
                                      :item-index="index + 1"
                                      :product-detail="product"
                                      :checkBoxStatuses.sync="selectedProductsProxy"
            ></meeting-detail-accordion>
          </div>
          <div class="d-flex align-items-end justify-content-center mt-4">
            <button @click="toggleModal" :disabled="selectedProducts.length === 0" class="btn btn-sm btn-outline-primary btn-pill font-weight-boldest">
              {{ $t("general.convert_collection_to_sample") }}
            </button>
            <!-- Modal-->
            <modal
                name="select-user-modal"
                :adaptive="true"
                height="auto"
                width="750"
            >
              <select-sample-user-modal
                  :users="sampleUsers"
                  :model.sync="selectedUserProxy"
                  @onCloseModal="onCloseModal"
                  @onConfirm="onCreateTask"
              ></select-sample-user-modal>
            </modal>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapGetters, mapMutations} from 'vuex'
import MeetingDetailAccordion from "@/view/pages/samples/layout/meeting-detail/MeetingDetailAccordion";

import DashboardBox from "@/assets/components/DashboardBox";
import {
  CREATE_SAMPLE,
  ERROR,
  GET_MEETING_DETAIL, GET_SAMPLE_USERS,
  LOADING,
  MEETING, SAMPLE_USERS, SELECTED_PRODUCTS, SELECTED_USER,
  SET_LOADING, SET_SELECTED_PRODUCTS, SET_SELECTED_USER
} from "@/core/services/store/sample/create/meeting-detail.moduel";
import SelectSampleUserModal from "@/view/pages/samples/layout/meeting-detail/SelectSampleUserModal";
import SpinnerButton from "@/assets/components/card/SpinnerButton";

export default {
  name: "MeetingDetail",
  components: {
    SpinnerButton,
    SelectSampleUserModal,
    DashboardBox,
    MeetingDetailAccordion
  },
  computed: {
    ...mapGetters({
      meeting: MEETING,
      error: ERROR,
      loading: LOADING,
      selectedUser: SELECTED_USER,
      sampleUsers: SAMPLE_USERS,
      selectedProducts: SELECTED_PRODUCTS,
    }),
    selectedUserProxy: {
      set(value) {
        this.setSelectedUser(value);
      },
      get() {
        return this.selectedUser;
      }
    },
    selectedProductsProxy: {
      set(value) {
        this.setSelectedProducts(value);
      },
      get() {
        return this.selectedProducts;
      }
    },
  },
  data() {
    return {
      is_submitting: false,
      id: null,
      showBody: false,
    };
  },
  methods: {
    ...mapMutations({
      setSelectedUser: SET_SELECTED_USER,
      setSelectedProducts: SET_SELECTED_PRODUCTS,
    }),
    toggleModal() {
      this.$modal.show('select-user-modal')
    },
    onCreateTask() {
      if(!this.isUserGranted('Sample', ['update', 'view'], false) || !this.isUserGranted('Staff', ['customerRepresentative'], false)) {
        setTimeout(() => {
          self.sweetAlertError(this.$t('general.you_have_no_authorized_to_do_this_action'));
        }, 500);
        return false;
      }

      if(this.is_submitting) return;
      this.is_submitting = true;
      this.$store.dispatch(CREATE_SAMPLE, {
        onSuccess: function (self) {
          self.is_submitting = false;
          self.sweetAlertSuccess(self.$t('sample.on_create_sample_from_meeting_success_message'), function (self) {
            self.$router.push({name: 'sample.create.meeting-list'});
          })
        }, self: this
      });
    },
    onCloseModal() {
      this.$modal.hide('select-user-modal')
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t('general.sample'), route: "sample"},
      {title: this.$t('general.meeting_detail')}
    ]);

    if(!this.isUserGranted('Sample', ['create'])) {
      return false;
    }

    this.id = this.$route.params.id;

    if (!this.id) {
      this.$router.push({name: "sample.create.meeting-list"})
    }
    this.$store.dispatch(GET_MEETING_DETAIL, {params: {id: this.id}})
    this.$store.dispatch(GET_SAMPLE_USERS);
  },

}
</script>

<style scoped>
.card-body-scroll {
  max-height: 55vh;
  overflow-y: scroll;
}

.company-thumbnail-photo {
  width: 25px;
  height: 25px;
}

.user-image {
  border-radius: 50%;
}
.company-image-border{
  border: 2px solid #1cac79;
}
*:focus {
  outline: none;
}
</style>
